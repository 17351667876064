import { Component, Input, OnChanges, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from '../base-component/base.component';
import { MatFormFieldAppearance } from '@angular/material/form-field';

@UntilDestroy()
@Component({
  template: '',
})
export class FormInputBaseComponent<Type, OnChangeType = Type>
  extends BaseComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() id: string;
  @Input() name: string;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() alwaysShowErrors = false;
  @Input() customError: { error: string; message: string };
  @Input() showError = true;
  @Input() appearance: MatFormFieldAppearance = 'outline';

  onChange: (value: OnChangeType) => void;
  onTouched: (value?: unknown) => void;

  value: Type;
  control: AbstractControl | FormControl<Type>;
  formControlName: string;

  errorMessage: string;

  constructor(
    // Retrieve the dependency only from the local injector, not from parent or ancestors.
    @Self()
    // We want to be able to use the component without a form, so we mark the dependency as optional.
    @Optional()
    protected ngControl: NgControl
  ) {
    super();

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.ngControl) {
      this.control = this.ngControl.control;
      this.formControlName = this.ngControl.name as string;
    }

    this.handleFormStatusChange();

    return super.ngOnInit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.showError || changes.alwaysShowErrors || changes.customError) {
      this.setErrorMessage();
    }
  }

  handleFormStatusChange() {
    this.control.statusChanges.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.setErrorMessage();
      },
    });
  }

  registerOnChange(onChangeFn: (value: OnChangeType) => void): void {
    this.onChange = onChangeFn;
  }

  registerOnTouched(onTouchedFn: (value?: unknown) => void): void {
    this.onTouched = (value?: unknown) => {
      onTouchedFn(value);
      this.setErrorMessage();
    };
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: Type): void {
    this.value = value;
  }

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    this.onChange(inputElement.value as OnChangeType);
    this.writeValue(inputElement.value as Type);
  }

  setErrorMessage(): void {
    if (!this.showError || !this.control || (this.control.pristine && !this.alwaysShowErrors)) {
      this.errorMessage = '';
      return;
    }

    const errorPrefix = 'FormComponents.Error.';
    let errorSuffix = '';

    if (this.customError) {
      errorSuffix = this.handleCustomError();
    } else if (this.control.hasError('required')) {
      errorSuffix = 'Required';
    } else {
      errorSuffix = this.control.errors ? 'ValueInvalid' : '';
    }

    this.errorMessage = errorSuffix ? errorPrefix + errorSuffix : errorSuffix;
  }

  private handleCustomError(): string {
    return this.control.hasError(this.customError.error) ? this.customError.message : '';
  }
}
