import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { BaseComponent } from '../../base-component/base.component';

@UntilDestroy()
@Component({
  selector: 'app-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
})
export class FormHeaderComponent extends BaseComponent {
  @Input() title: string;
  @Input() previousRoute: string;
  @Input() icon = 'arrow_back';

  constructor(private router: Router) {
    super();
  }

  navigate(): void {
    this.router.navigate([this.previousRoute]);
  }
}
