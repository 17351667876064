<mat-form-field [appearance]="appearance">
    <mat-label>{{placeholder}}</mat-label>
    <input
        matInput
        autocomplete="new-password"
        appWithTestId
        [testIdName]="formControlName"
        [type]="type"
        [placeholder]="placeholder"
        [value]="valueString"
        [disabled]="disabled"
        [matDatepicker]="$any(matDatePicker)"
        (dateChange)="onDateChange($event)"
        (input)="onInputChange($event)"
        (blur)="onTouched()"/>
    <mat-datepicker #matDatePicker></mat-datepicker>
    <mat-datepicker-toggle matSuffix [for]="matDatePicker"></mat-datepicker-toggle>
    <mat-hint *ngIf="errorMessage" class="error-message">{{errorMessage | translate}}</mat-hint>
</mat-form-field>
