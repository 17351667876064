<mat-form-field [color]="color" [appearance]="appearance">
    <mat-label [class.mat-error]='!!errorMessage'>
        {{label | translate}}
    </mat-label>
    <textarea
        appWithTestId
        [testIdName]="formControlName"
        matInput
        cdkTextareaAutosize
        [cdkAutosizeMinRows]="cdkAutosizeMinRows"
        [cdkAutosizeMaxRows]="cdkAutosizeMaxRows"
        [placeholder]='placeholder | translate'
        [value]='value'
        [disabled]='disabled'
        (keyup)='keyUp.emit()'
        (input)="onInputChange($event)"
        (blur)='onTouched()'>
    </textarea>
    <mat-hint *ngIf="errorMessage" class="error-message">{{errorMessage | translate}}</mat-hint>
</mat-form-field>
