import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  loadScript(scriptSrc: string): Promise<unknown> {
    return new Promise<void>((resolve, reject) => {
      const existingScript = this.document.querySelector(`script[src="${scriptSrc}"]`);

      if (existingScript) {
        resolve();
      } else {
        const script = this.document.createElement('script');
        script.type = 'text/javascript';
        script.src = scriptSrc;
        script.onload = () => {
          resolve();
        };
        script.onerror = (error: unknown) => {
          reject(error);
        };
        document.body.appendChild(script);
      }
    });
  }
}
