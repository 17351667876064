<div class="mat-mdc-form-field w-100">
    <mat-label [class.mat-error]='!!errorMessage'>{{label}}</mat-label>
    <ng-select
        appearance="outline"
        appWithTestId
        [testIdName]="formControlName"
        class="extra-select"
        [labelForId]="id"
        [items]="options"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"
        [placeholder]="placeholder"
        [multiple]="multiple"
        [disabled]="disabled"
        [(ngModel)]="value"
        [searchable]="searchable"
        [clearable]="clearable"
        (change)="onChange($event)"
        (focus)="onTouched($event)">
    </ng-select>
    <mat-hint *ngIf="errorMessage" class="error-message">{{errorMessage | translate}}</mat-hint>
</div>
