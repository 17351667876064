<div class='mat-mdc-form-field w-100'>
    <mat-label [class.mat-error]='!!errorMessage'>{{label}}</mat-label>
    <mat-radio-group
        class="radio-group"
        [ngClass]="isVerticalLayout ? 'vertical' : 'horizontal'"
        [disabled]="disabled"
        [(ngModel)]="value"
        [color]="color"
        (change)="onChange($event.value)"
        (focus)="onTouched($event)">

         <mat-radio-button class="unit-test"
             #radioButton
             *ngFor="let option of options"
             [value]="option.value"
             appWithTestId
             [testIdName]="formControlName"
             [testIdPostfix]=radioButton.inputId>
             {{option.label}}
         </mat-radio-button>

    </mat-radio-group>
    <mat-hint *ngIf="showError && errorMessage" class="error-message">{{errorMessage | translate}}</mat-hint>
</div>
