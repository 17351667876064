import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { google } from 'google-maps';
import { ThemePalette } from '@angular/material/core';
import { NgControl } from '@angular/forms';
import { FormInputBaseComponent } from '../form-input-base.component';
import { ScriptLoaderService } from '../../../services/script-loader.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { LanguageService } from '../../../services/language.service';
import { AutocompletionTypes } from './classes/autocompletion-types';
import { AddressComponentType } from './classes/address-component-type';
import { PlaceType } from './classes/place-type';
import { SearchedAddress } from '../../../../classes/user/address-model';
import { FormComponentsConfig } from '../../../../config/form-components.config';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-place-autocomplete-field',
  templateUrl: './place-autocomplete-field.component.html',
  styleUrls: ['./place-autocomplete-field.component.scss'],
})
export class PlaceAutocompleteFieldComponent extends FormInputBaseComponent<string> implements OnInit {
  @Input() color: ThemePalette = 'primary';
  @Input() type: 'search';
  @Input() placeholder: string;
  @Input() country: string | string[] = 'hu';
  @Input() clearableInput = true;

  @Output() placeChanged = new EventEmitter<SearchedAddress>();

  placePredictions: PlaceType[] = [];
  autocompleteSession: google.maps.places.AutocompleteSessionToken;
  autocompleteInput: string;
  isScriptActive: boolean = false;
  googleScriptUrl: string = `https://maps.googleapis.com/maps/api/js?key=${environment.google.mapApiKey}&libraries=places`;
  parsedAddress: SearchedAddress;

  constructor(
    protected ngControl: NgControl,
    protected scriptLoaderService: ScriptLoaderService,
    private languageService: LanguageService
  ) {
    super(ngControl);
  }

  ngOnInit() {
    this.loadGoogleMapsApi();
    return super.ngOnInit();
  }

  private loadGoogleMapsApi(): void {
    this.scriptLoaderService.loadScript(this.googleScriptUrl).then(() => {
      this.isScriptActive = true;
    });
  }

  clearAddressField() {
    this.autocompleteInput = null;
    this.placePredictions = [];
    this.setControlValue();
  }

  private setControlValue() {
    this.onChange(this.value);
    this.writeValue(this.value);
  }

  searchAddress(event: Event) {
    if (!this.autocompleteSession && this.isScriptActive) {
      this.autocompleteSession = new google.maps.places.AutocompleteSessionToken();
    }

    if (this.autocompleteSession && event.type !== 'click') {
      this.getAutocompletePredictions();
    }
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const selectedAddress: PlaceType = this.placePredictions.find(
      (prediction) => prediction.description === event.option.value
    );
    this.autocompleteSession = null;
    this.getAddressDetails(selectedAddress);
  }

  private getAutocompletePredictions() {
    if (this.autocompleteInput?.length <= FormComponentsConfig.MIN_LENGTH_OF_SEARCH) {
      this.placePredictions = [];
      return;
    }

    this.getPlacePredictions(this.autocompleteInput)
      .then((predictions) => {
        this.placePredictions = predictions.map((prediction) => {
          return {
            placeId: prediction.place_id,
            description: prediction.description,
          };
        });
      })
      .catch(() => (this.placePredictions = []));
  }

  private getPlacePredictions(query: string): Promise<google.maps.places.AutocompletePrediction[]> {
    const autocompleteService = new google.maps.places.AutocompleteService();
    const request = {
      input: query,
      types: [AutocompletionTypes.Geocode, AutocompletionTypes.Establishment],
      componentRestrictions: {
        country: this.country,
      },
      sessionToken: this.autocompleteSession,
      language: this.languageService.currentLang,
    };

    return new Promise((resolve, reject) => {
      autocompleteService.getPlacePredictions(request, (predictions, status) => {
        if (request.input !== '' && status == google.maps.places.PlacesServiceStatus.OK) {
          resolve(predictions);
        } else {
          reject(status);
        }
      });
    });
  }

  private getAddressDetails(place: PlaceType) {
    if (!place) {
      return;
    }

    if (place.placeId) {
      const placesService = new google.maps.places.PlacesService(document.createElement('div'));
      const request = {
        placeId: place?.placeId,
        fields: ['address_components'],
      };

      placesService.getDetails(request, (placeResult, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const components = placeResult.address_components;

          this.placeChanged.emit(this.mapAddress(components));
        }
      });
    }
  }

  private mapAddress(addressComponents: google.maps.GeocoderAddressComponent[]): SearchedAddress {
    this.parsedAddress = {
      address: '',
      country: '',
      state: '',
      zipCode: '',
      city: '',
      street: '',
      number: '',
    };

    addressComponents.forEach((component) => {
      const componentType = component.types[0];
      switch (componentType) {
        case AddressComponentType.Country:
          this.parsedAddress.country = component.long_name;
          break;
        case AddressComponentType.State:
          this.parsedAddress.state = component.long_name;
          break;
        case AddressComponentType.ZipCode:
          this.parsedAddress.zipCode = component.short_name;
          break;
        case AddressComponentType.City:
          this.parsedAddress.city = component.long_name;
          break;
        case AddressComponentType.Street:
          this.parsedAddress.street = component.long_name;
          break;
        case AddressComponentType.Number:
          this.parsedAddress.number = component.long_name;
          break;
      }
    });
    return this.parsedAddress;
  }
}
