<div class='mat-mdc-form-field w-100'>
    <mat-checkbox
        appWithTestId
        [testIdName]="formControlName"
        [disabled]="disabled"
        [checked]="!!value"
        [indeterminate]="false"
        [color]="color"
        [(ngModel)]="value"
        (change)="onChange(!!$event.checked)"
        (focus)="onTouched($event)">
        <ng-container *ngIf="label">{{label}}</ng-container>
        <ng-content select="[htmlLabel]"></ng-content>
    </mat-checkbox>
    <mat-hint *ngIf="showError" class="error-message">{{errorMessage | translate}}</mat-hint>
</div>
