<mat-form-field [appearance]="appearance" class="w-100">
    <mat-label [class.mat-error]="!!errorMessage">
        {{label | translate}}
    </mat-label>

    <mat-chip-grid #chipList>
        <mat-chip-row *ngFor="let element of value; let index = index" (removed)="removeElement(index)">
            {{element}}
            <button matChipRemove class="d-flex align-items-center">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-row>

        <input
            matInput
            [placeholder]="placeholder | translate"
            [matChipInputFor]="chipList"
            (matChipInputTokenEnd)="valueChange($event)">

        <button
            *ngIf="value?.length && clearableInput"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="emptyList()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-chip-grid>

    <mat-hint class="me-4">{{'FormComponents.Hints.AddNewWithEnter' | translate }}</mat-hint>
    <mat-hint *ngIf="errorMessage" class="error-message">{{errorMessage | translate}}</mat-hint>
</mat-form-field>
