import { Component, Input } from '@angular/core';
import { FormInputBaseComponent } from '../form-input-base.component';
import { RadioGroupOption } from '../classes/radio-group-option';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent extends FormInputBaseComponent<unknown> {
  @Input() options: RadioGroupOption[];
  @Input() isVerticalLayout = false;
  @Input() color: ThemePalette = 'accent';
}
