import { LanguageTypes } from './language-types.interface';

export enum Language {
  Hu = 'hu',
  En = 'en',
}

export class LanguageConfig {
  static readonly LANGUAGES = [Language.En, Language.Hu];
  static readonly DEFAULT_LANGUAGE = Language.En;
  static readonly LOCALSTORAGE_KEY = 'lang';
}

export const Languages: LanguageTypes[] = [
  {
    slug: Language.Hu,
    label: 'Magyar',
    src: 'assets/image/flags/hu.svg',
    alt: 'hungarian_flag',
  },
  {
    slug: Language.En,
    label: 'English',
    src: 'assets/image/flags/en.svg',
    alt: 'gb_flag',
  },
];
