import { Component, Input, OnInit } from '@angular/core';
import { FormInputBaseComponent } from '../form-input-base.component';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
})
export class SlideToggleComponent extends FormInputBaseComponent<boolean> implements OnInit {
  @Input() color: ThemePalette = 'primary';
}
