import { Injectable } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { AppConfig } from '../../config/app.config';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  deviceInfo: DeviceInfo;

  constructor(private deviceService: DeviceDetectorService) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    console.log('browser info: ', this.deviceInfo);
  }

  get isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  get isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  get isTablet(): boolean {
    return this.deviceService.isTablet();
  }

  get isIE(): boolean {
    return this.deviceInfo.browser === 'IE';
  }

  get isIE11(): boolean {
    return this.isIE && this.deviceInfo.browser_version === '11.0';
  }

  get isSafari(): boolean {
    return this.deviceInfo.browser === 'Safari';
  }

  get isEdge(): boolean {
    return this.deviceInfo.browser === 'MS-Edge';
  }

  getDeviceInfo(): DeviceInfo {
    return this.deviceInfo;
  }

  isBrowserSupported(): boolean {
    if (this.isSafari && AppConfig.BROWSER_NOT_SUPPORTED.safari) {
      console.warn('safari not supported!');
      return false;
    }
    if (this.isEdge && AppConfig.BROWSER_NOT_SUPPORTED.edge) {
      console.warn('edge not supported!');
      return false;
    }
    if (this.isIE11 && AppConfig.BROWSER_NOT_SUPPORTED.ie11) {
      console.warn('ie11 not supported!');
      return false;
    }
    if (this.isIE && !this.isIE11 && AppConfig.BROWSER_NOT_SUPPORTED.ie10) {
      console.warn('< ie11 and not supported!');
      return false;
    }

    return true;
  }
}
