import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { FormInputBaseComponent } from './form-input-base.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { ButtonComponent } from './button/button.component';
import { NgSelectComponent } from './ng-select/ng-select.component';
import { TextFieldComponent } from './text-field/text-field.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FormHeaderComponent } from './form-header/form-header.component';
import { ControlValueChangeListenerDirective } from './control-value-change-listener.directive';
import { TextareaFieldComponent } from './textarea-field/textarea-field.component';
import { SelectComponent } from './select/select.component';
import { SharedModule } from '../../shared.module';
import { SlideToggleComponent } from './slide-toggle/slide-toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ColorPickerComponent } from './color-picker-input-field/color-picker/color-picker.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ColorPickerInputFieldComponent } from './color-picker-input-field/color-picker-input-field.component';
import { MultiselectInputFieldComponent } from './multiselect-input-field/multiselect-input-field.component';
import { MatChipsModule } from '@angular/material/chips';
import { PlaceAutocompleteFieldComponent } from './place-autocomplete-field/place-autocomplete-field.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
  declarations: [
    TextFieldComponent,
    SelectComponent,
    FormInputBaseComponent,
    CheckboxComponent,
    RadioGroupComponent,
    ButtonComponent,
    DatePickerComponent,
    FormHeaderComponent,
    ControlValueChangeListenerDirective,
    TextareaFieldComponent,
    NgSelectComponent,
    SlideToggleComponent,
    ColorPickerComponent,
    ColorPickerInputFieldComponent,
    MultiselectInputFieldComponent,
    PlaceAutocompleteFieldComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    TranslateModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    SharedModule,
    MatSlideToggleModule,
    ColorPickerModule,
    MatChipsModule,
    MatAutocompleteModule,
  ],
  exports: [
    TextFieldComponent,
    SelectComponent,
    CheckboxComponent,
    RadioGroupComponent,
    ButtonComponent,
    DatePickerComponent,
    FormHeaderComponent,
    TextareaFieldComponent,
    NgSelectComponent,
    SlideToggleComponent,
    ColorPickerComponent,
    ColorPickerInputFieldComponent,
    NgSelectComponent,
    MultiselectInputFieldComponent,
    PlaceAutocompleteFieldComponent,
  ],
})
export class FormComponentsModule {}
