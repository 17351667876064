<ng-container [ngSwitch]="buttonClass">
    <button *ngSwitchDefault
            mat-button
            appWithTestId
            [testIdName]="color"
            [ngClass]="buttonClass"
            [type]="type"
            [disabled]="disabled"
            [color]="$any(color)">
        <mat-icon *ngIf="icon">{{icon}}</mat-icon>
        {{text | translate}}
    </button>
    <button *ngSwitchCase="BUTTON_CLASS.Raised"
            mat-raised-button
            appWithTestId
            [testIdName]="color"
            [ngClass]="buttonClass"
            [type]="type"
            [disabled]="disabled"
            [color]="$any(color)">
        <mat-icon *ngIf="icon">{{icon}}</mat-icon>
        {{text | translate}}
    </button>
    <button *ngSwitchCase="BUTTON_CLASS.Stroked"
            mat-stroked-button
            appWithTestId
            [testIdName]="color"
            [ngClass]="buttonClass"
            [type]="type"
            [disabled]="disabled"
            [color]="$any(color)">
        <mat-icon *ngIf="icon">{{icon}}</mat-icon>
        {{text | translate}}
    </button>
    <button *ngSwitchCase="BUTTON_CLASS.Flat"
            mat-flat-button
            appWithTestId
            [testIdName]="color"
            [ngClass]="buttonClass"
            [type]="type"
            [disabled]="disabled"
            [color]="$any(color)">
        <mat-icon *ngIf="icon">{{icon}}</mat-icon>
        {{text | translate}}
    </button>
    <button *ngSwitchCase="BUTTON_CLASS.Icon"
            mat-icon-button
            appWithTestId
            [testIdName]="color"
            [ngClass]="buttonClass"
            [type]="type"
            [disabled]="disabled"
            [color]="$any(color)">
        <mat-icon *ngIf="icon">{{icon}}</mat-icon>
        {{text | translate}}
    </button>
    <button *ngSwitchCase="BUTTON_CLASS.Fab"
            mat-fab
            appWithTestId
            [testIdName]="color"
            [ngClass]="buttonClass"
            [type]="type"
            [disabled]="disabled"
            [color]="$any(color)">
        <mat-icon *ngIf="icon">{{icon}}</mat-icon>
        {{text | translate}}
    </button>
    <button *ngSwitchCase="BUTTON_CLASS.MiniFab"
            mat-mini-fab
            appWithTestId
            [testIdName]="color"
            [ngClass]="buttonClass"
            [type]="type"
            [disabled]="disabled"
            [color]="$any(color)">
        <mat-icon *ngIf="icon">{{icon}}</mat-icon>
        {{text | translate}}
    </button>
</ng-container>
