import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormInputBaseComponent } from '../form-input-base.component';
import { ThemePalette } from '@angular/material/core';

@UntilDestroy()
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent extends FormInputBaseComponent<boolean> {
  @Input() color: ThemePalette = 'primary';
}
