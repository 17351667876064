import { Component, Input } from '@angular/core';
import { FormInputBaseComponent } from '../form-input-base.component';
import { DropdownOption } from '../classes/dropdown-option';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends FormInputBaseComponent<string | number> {
  @Input() id: string;
  @Input() options: DropdownOption[];
  @Input() color: ThemePalette = 'primary';
}
