<mat-form-field [color]="color" [appearance]="appearance">

    <mat-label [class.mat-error]="!!errorMessage">
        {{placeholder | translate}}
    </mat-label>

    <input [id]="'search'"
           matInput
           appWithTestId
           [testIdName]="'placeAutoComplete'"
           [matAutocomplete]="auto"
           [(ngModel)]="autocompleteInput"
           (ngModelChange)="searchAddress($event)"
           [placeholder]="placeholder | translate" />

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
        <mat-option *ngFor="let address of placePredictions" [value]="address.description">
            <mat-icon>room</mat-icon>
            {{ address.description }}
        </mat-option>
        <mat-option *ngIf="placePredictions.length === 0" disabled>
            {{'FormComponents.Autocomplete.NoLocation'| translate}}
        </mat-option>
    </mat-autocomplete>

    <button *ngIf="this.autocompleteInput?.length && clearableInput"
            matIconSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clearAddressField()">
        <mat-icon>clear</mat-icon>
    </button>

    <mat-hint *ngIf="showError" class="error-message">{{errorMessage | translate}}</mat-hint>
</mat-form-field>
