<mat-form-field [appearance]="appearance" [color]="color" class="d-flex">
    <div matTextPrefix [class.disabled]="disabled">#</div>
    <input
        appWithTestId
        [testIdName]="formControlName"
        matInput
        appControlValueChangeListener
        [placeholder]="placeholder | translate"
        [value]="valueWithoutSharpChar"
        [disabled]="disabled"
        (input)="onInputChange($event)"
        (blur)="onTouched()"
        type="text"/>
    <mat-hint *ngIf="errorMessage" class="error-message">{{errorMessage | translate}}</mat-hint>
</mat-form-field>

<app-color-picker
    class="input-color-picker d-flex align-self-center"
    [(color)]="value"
    [disabled]="disabled"
    (colorChange)="onChange($event)">
</app-color-picker>
