import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseComponent } from '../../base-component/base.component';
import { ButtonClass } from './classes/button-class';
import { ButtonColor } from './classes/button-color';

@UntilDestroy()
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent extends BaseComponent {
  @Input() text: string;
  @Input() icon = '';
  @Input() disabled = false;
  @Input() type: 'submit' | 'button' | 'reset' = 'button';
  @Input() color: ThemePalette | ButtonColor = 'accent';
  @Input() buttonClass: ButtonClass = ButtonClass.Raised;

  readonly BUTTON_CLASS = ButtonClass;
}
