<mat-form-field class='w-100' [appearance]="appearance" [color]="color">
    <mat-label [class.mat-error]='!!errorMessage'>
        {{placeholder | translate}}
    </mat-label>
    <mat-select
        appWithTestId
        [disabled]="disabled"
        [testIdName]="formControlName"
        [value]='value'
        (selectionChange)='onChange($event.value)'
        (openedChange)='onTouched($event)'>
        <mat-option *ngFor='let option of options' [value]='option.value'>
            {{option.label}}
        </mat-option>
    </mat-select>
    <mat-hint *ngIf="errorMessage" class="error-message">{{errorMessage | translate}}</mat-hint>
</mat-form-field>
