import { Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FormInputBaseComponent } from '../form-input-base.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@UntilDestroy()
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent extends FormInputBaseComponent<Date> {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() type: 'text' | 'email' | 'password' = 'text';

  // in the template need to add $any(matDatePicker) because of template type check error
  // after searching it looks like webstorm issue, no any proper solution exists yet
  valueString: string;

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    this.onChange(event.target.value);
  }

  override writeValue(value: Date) {
    super.writeValue(value);

    this.valueString = value?.toString() ?? '';
  }
}
