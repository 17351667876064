<div class='mat-mdc-form-field w-100'>
    <mat-slide-toggle
        [color]="color"
        [(ngModel)]="value"
        [checked]="!!value"
        (change)="onChange(!!$event.checked)"
        (focus)="onTouched($event)"
        [disabled]="disabled">
        {{label}}
    </mat-slide-toggle>
    <mat-hint *ngIf="showError" class="error-message">{{errorMessage | translate}}</mat-hint>
</div>
