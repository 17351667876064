import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent {
  @Input() color: string;
  @Input() disabled: boolean;
  @Output() colorChange = new EventEmitter<string>();

  onColorChange(event: string): void {
    this.colorChange.emit(event);
  }
}
