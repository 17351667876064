<div class="d-flex align-items-center">
    <app-button
            [type]="'button'"
            [buttonClass]="BUTTON_CLASS.Icon"
            [icon]="icon"
            [text]="''"
            (click)="navigate()">
    </app-button>
    <h1 class="ms-4">{{title | translate}}</h1>
</div>
