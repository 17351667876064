import { Component, Input } from '@angular/core';
import { FormInputBaseComponent } from '../form-input-base.component';
import { DropdownOption } from '../classes/dropdown-option';

@Component({
  selector: 'app-ng-select',
  templateUrl: './ng-select.component.html',
  styleUrls: ['./ng-select.component.scss'],
})
export class NgSelectComponent extends FormInputBaseComponent<string | boolean, DropdownOption> {
  @Input() id: string;
  @Input() options: DropdownOption[];
  @Input() searchable = false;
  @Input() clearable = false;
  @Input() multiple = false;

  bindLabel = 'label';
  bindValue = 'value';
}
