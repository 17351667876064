export class AppConfig {
  static readonly COOKIE_ACCEPTED_NAME = 'cookie-accepted';

  static readonly ROUTER_TRANSITION_TIMING = '.5s cubic-bezier(0.77, 0, 0.175, 1)';

  static readonly LOW_RESOLUTION_LIMIT = 768;
  static readonly LOW_RES_ANIMATION_NAME = 'low-res';

  static readonly BROWSER_NOT_SUPPORTED = {
    ie11: false,
    ie10: true,
    edge: false,
    safari: false,
  };
}
