import { Injectable } from '@angular/core';
import { Language, LanguageConfig } from '../modules/language-picker/language.config';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private translate: TranslateService) {}

  init(): void {
    const savedLang = localStorage.getItem(LanguageConfig.LOCALSTORAGE_KEY) as Language;
    const defaultLang = LanguageConfig.DEFAULT_LANGUAGE;

    this.translate.addLangs(LanguageConfig.LANGUAGES);

    this.translate.setDefaultLang(LanguageConfig.DEFAULT_LANGUAGE);

    if (savedLang && LanguageConfig.LANGUAGES.includes(savedLang)) {
      this.setLanguage(savedLang);
    } else {
      this.setLanguage(defaultLang);
    }
  }

  setLanguage(lang: Language): void {
    this.translate.use(lang);

    localStorage.setItem(LanguageConfig.LOCALSTORAGE_KEY, lang);
  }

  getAvailableLanguages(): Language[] {
    return LanguageConfig.LANGUAGES;
  }

  get currentLang(): Language {
    return this.translate.currentLang as Language;
  }
}
