import { Component, Input } from '@angular/core';
import { FormInputBaseComponent } from '../form-input-base.component';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-color-picker-input-field',
  templateUrl: './color-picker-input-field.component.html',
  styleUrls: ['./color-picker-input-field.component.scss'],
})
export class ColorPickerInputFieldComponent extends FormInputBaseComponent<string> {
  @Input() color: ThemePalette = 'primary';

  override onInputChange(event: Event): void {
    const value = `#${(event.target as HTMLInputElement).value}`;

    this.onChange(value);
    this.writeValue(value);
  }

  get valueWithoutSharpChar(): string {
    return this.value?.slice(1) ?? '';
  }
}
