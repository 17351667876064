import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateAppService extends TranslateService {
  instant(key: string | Array<string>, interpolateParams?: object): string {
    return super.instant(key, interpolateParams) as string;
  }
}
