import { Component, Input, Renderer2 } from '@angular/core';
import { FormInputBaseComponent } from '../form-input-base.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-multiselect-input-field',
  templateUrl: './multiselect-input-field.component.html',
  styleUrls: ['./multiselect-input-field.component.scss'],
})
export class MultiselectInputFieldComponent extends FormInputBaseComponent<string[]> {
  @Input() clearableInput = false;

  constructor(
    protected ngControl: NgControl,
    private renderer: Renderer2
  ) {
    super(ngControl);
  }

  valueChange(event: MatChipInputEvent): void {
    if (!this.value) {
      this.value = [];
    }

    const trimmedValue = event.value.trim();

    if (trimmedValue.length) {
      this.value.push(trimmedValue);
      this.setControlValue();
      this.renderer.setProperty(event.chipInput.inputElement, 'value', null);
    }
  }

  removeElement(index: number) {
    this.value.splice(index, 1);

    this.setControlValue();
  }

  emptyList() {
    this.value = null;

    this.setControlValue();
  }

  private setControlValue() {
    this.onChange(this.value);
    this.writeValue(this.value);
  }
}
