import { Component, EventEmitter, Output, Input } from '@angular/core';
import { FormInputBaseComponent } from '../form-input-base.component';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-textarea-field',
  templateUrl: './textarea-field.component.html',
  styleUrls: ['./textarea-field.component.scss'],
})
export class TextareaFieldComponent extends FormInputBaseComponent<string> {
  @Output() keyUp: EventEmitter<void> = new EventEmitter<void>();
  @Input() color: ThemePalette = 'primary';
  @Input() cdkAutosizeMinRows: number;
  @Input() cdkAutosizeMaxRows: number;
}
