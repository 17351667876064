import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormInputBaseComponent } from '../form-input-base.component';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss'],
})
export class TextFieldComponent extends FormInputBaseComponent<string> {
  @Input() autocomplete: string;
  @Input() type: 'text' | 'email' | 'password' | 'search' = 'text';
  @Input() color: ThemePalette = 'primary';
  @Output() keyUp: EventEmitter<void> = new EventEmitter<void>();

  get isAutoCompleteOff(): boolean {
    return this.autocomplete === 'off';
  }
}
