<mat-form-field [color]="color" [appearance]="appearance">
    <mat-label [class.mat-error]='!!errorMessage'>
        {{label | translate}}
    </mat-label>
    <input
        appWithTestId
        [testIdName]="formControlName"
        matInput
        appControlValueChangeListener
        [autocomplete]="autocomplete"
        [type]="isAutoCompleteOff ? type : 'search'"
        [placeholder]="placeholder | translate"
        [value]="value"
        [disabled]="disabled"
        (keyup)="keyUp.emit()"
        (input)="onInputChange($event)"
        (blur)="onTouched()"/>
    <mat-hint *ngIf="errorMessage" class="error-message">{{errorMessage | translate}}</mat-hint>
</mat-form-field>
